<template>
  <v-row>
    <v-col cols="12" md="6">
      <SelectWarehouse
        :label="$t('labels.warehouse')"
        :placeholder="$t('labels.warehouse')"
        name="id_warehouse"
        @onFilter="onWarehouseChange"
        class="mb-5"
      />

      <v-text-field
        v-model="current_cell_id"
        :disabled="!id_warehouse"
        :label="$t('labels.current_position_suggest')"
        :placeholder="$t('labels.current_position_suggest')"
        class="c-input-xs mb-5"
        ref="inputIndex_1"
        :error="validates.includes('current_cell_id')"
        @keyup.enter="suggestByCell"
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>

      <div class="mb-5" v-if="suggest_cells.length > 0">
        {{ $t("labels.suggest") }}:
        <span
          style="font-size: 16px; font-weight: 600; color: red"
          v-for="c in suggest_cells"
          :key="`${c.cell_id}_${c.indexing}`"
          >{{ c.cell_id }};
        </span>
      </div>

      <!--<v-autocomplete v-model="type" :items="typeOptions" :disabled="!id_warehouse" class="c-input-xs mb-5"
                      ref="inputIndex_2" :error="validates.includes('type')" @change="nextInputFocus(2)"
                      :label="$t('labels.find_by_all')" :placeholder="$t('labels.find_by_all')" dense outlined hide-details
                      single-line></v-autocomplete>-->

      <v-text-field
        v-model="code"
        :disabled="!id_warehouse"
        class="c-input-xs mb-5"
        ref="inputIndex_3"
        :error="validates.includes('code')"
        :label="$t('labels.sku_barcode_sub_barcode')"
        :placeholder="$t('labels.sku_barcode_sub_barcode')"
        @keyup.enter="getGoods"
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>

      <v-autocomplete
        v-if="customerGoodsOptions.length > 0"
        v-model="id_goods"
        :items="customerGoodsOptions"
        :disabled="!id_warehouse"
        class="c-input-xs mb-5"
        ref="inputIndex_4"
        :error="validates.includes('id_goods')"
        @change="nextInputFocus(4)"
        :label="$t('labels.customer')"
        :placeholder="$t('labels.customer')"
        dense
        outlined
        hide-details
        single-line
      ></v-autocomplete>

      <v-text-field
        v-model="old_cell_id"
        :disabled="!id_warehouse || !id_goods"
        class="c-input-xs mb-5"
        ref="inputIndex_5"
        :error="validates.includes('old_cell_id')"
        :label="$t('labels.old_position')"
        :placeholder="$t('labels.old_position')"
        @keyup.enter="nextInputFocus(5)"
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="new_cell_id"
        :disabled="!id_warehouse || !old_cell_id"
        class="c-input-xs mb-5"
        ref="inputIndex_6"
        :error="validates.includes('new_cell_id')"
        :label="$t('labels.new_position')"
        :placeholder="$t('labels.new_position')"
        @keyup.enter="showPopupConfirm"
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>

      <small v-if="errMsg" class="red--text">{{ errMsg }}</small>
    </v-col>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.confirm_position") }}
        </v-card-title>
        <v-card-text>
          <div
            v-html="
              $t('messages.confirm_change_position', {
                old_position: old_cell_id,
                new_position: new_cell_id,
              })
            "
          ></div>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="confirm_cell_id"
            class="c-input-xs"
            :label="$t('labels.scan_position_to_confirm')"
            :placeholder="$t('labels.scan_position_to_confirm')"
            ref="inputIndex_7"
            @keyup.enter="changeLocation"
            dense
            single-line
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogConfirm = false">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="changeLocation">
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "LocationChange",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
  },
  data: () => ({
    id_warehouse: null,
    id_goods: null,
    current_cell_id: null,
    old_cell_id: null,
    new_cell_id: null,
    confirm_cell_id: null,
    type: null,
    code: null,
    errMsg: null,

    suggest_cells: [],
    goods_customers: [],

    validates: [],

    dialogConfirm: false,
    isLoading: false,

    typeOptions: [],
  }),
  computed: {
    customerGoodsOptions() {
      return this.goods_customers.map((g) => ({
        text: `${g.company_name} (SL: ${g.available} - Vị trí: ${
          g.storage_location || ""
        })`,
        value: g.id,
      }));
    },
  },
  created() {
    const typeOptions = [
      {
        value: null,
        text: this.$t("labels.find_by_all"),
      },
      {
        value: "sku",
        text: this.$t("labels.sku"),
      },
      {
        value: "customer_goods_barcode",
        text: this.$t("labels.barcode"),
      },
      {
        value: "sub_barcode",
        text: this.$t("labels.sub_barcode"),
      },
    ];

    this.typeOptions = [...typeOptions];
  },
  methods: {
    nextInputFocus(index) {
      if (index > 10) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },

    onResetAll() {
      this.current_cell_id = null;
      this.suggest_cells = [];
      this.code = null;
      this.id_goods = null;
      this.old_cell_id = null;
      this.new_cell_id = null;
    },

    onWarehouseChange(item) {
      this.id_warehouse = item.value;
      this.onResetAll();
      this.nextInputFocus(0);
    },

    async showPopupConfirm() {
      this.dialogConfirm = true;
      setTimeout(() => {
        this.nextInputFocus(6);
      }, 500);
    },

    async hidePopupConfirm() {
      this.dialogConfirm = false;
      this.confirm_cell_id = null;
      this.nextInputFocus(2);
    },

    async suggestByCell() {
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/suggest-by-cell",
          {
            id_warehouse: this.id_warehouse,
            cell_id: this.current_cell_id,
          }
        );
        this.suggest_cells = [...data];
        this.current_cell_id = null;
        document.getElementById("success_sound_player").play();
        this.nextInputFocus(2);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async getGoods() {
      if (!this.code) {
        return false;
      }
      try {
        const codeSpl = this.code.split("@");
        const sku = codeSpl[0] || "";
        const { data } = await httpClient.post(
          "/goods/v1/get-by-code-type-and-warehouse",
          {
            id_warehouse: this.id_warehouse,
            code: sku,
            type: this.type,
          }
        );
        if (data.length === 1) {
          this.id_goods = data[0].id;
        }
        this.goods_customers = [...data];
        document.getElementById("success_sound_player").play();
        this.nextInputFocus(4);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async changeLocation() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      if (this.new_cell_id !== this.confirm_cell_id) {
        this.$vToastify.warning(this.$t("messages.not_match_location"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.isLoading = true;
      this.errMsg = null;
      this.validates = [];
      try {
        await httpClient.post("/warehouse-location/v1/change-location", {
          id_warehouse: this.id_warehouse,
          id_goods: this.id_goods,
          old_cell_id: this.old_cell_id,
          new_cell_id: this.new_cell_id,
        });
        this.isLoading = false;
        this.onResetAll();
        document.getElementById("success_sound_player").play();
        await this.hidePopupConfirm();
      } catch (e) {
        this.isLoading = false;
        this.new_cell_id = null;
        this.errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.validates) ||
          [];
        if (validates && validates.length > 0) {
          validates.forEach((v) => {
            this.validates.push(v.key);
          });
        }
        document.getElementById("error_sound_player").play();
        await this.hidePopupConfirm();
      }
    },
  },
};
</script>

<style scoped></style>
